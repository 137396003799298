import { useState } from 'react';
import { analytics, firestore } from '../../Firebase';
import sendEventToRollbar from '../../Utils/Rollbar';
import { mailCheck, nameCheck } from '../../Utils/Validation';
import { useCookies } from 'react-cookie';

const Terms = () => {

    const [cookie, setCookie, removeCookie] = useCookies(["email", "first_name", "last_name"]);

    const [termsAccepted, setTermsAccepted] = useState(false);
    const [usersFirstName, setUsersFirstName] = useState(null);
    const [usersLastName, setUsersLastName] = useState(null);
    const [usersEmail, setUsersEmail] = useState(null);
    const [acceptDate, setAcceptDate] = useState(null);

    const checkboxChange = (event) => {
        setTermsAccepted(event.target.checked);
        if(event.target.checked){
            setAcceptDate(new Date());
        }
    };

    const emailChange = (event) => {
        let email = event.target.value;
        let valid = mailCheck.test(email);
        if(valid){
            setUsersEmail(email);
        } else {
            setUsersEmail(null);
        }
    };

    const nameChange = (event) => {
        let name = event.target.value;
        let valid = nameCheck.test(name);
        if(valid){
            setUsersFirstName(name);
        } else {
            setUsersFirstName(null);
        }
    };

    const lastNameChange = (event) => {
        let name = event.target.value;
        let valid = nameCheck.test(name);
        if(valid){
            setUsersLastName(name);
        } else {
            setUsersLastName(null);
        }
    };

    const handleAccept = (event) => {
        console.log("Handle Accept");
        let user = {
            first_name: usersFirstName,
            last_name: usersLastName,
            email: usersEmail,
            date: acceptDate,
            ip: ""
        };

        //get the users ip
        fetch('https://api.ipify.org?format=json').then(response => {return response.json()}).then((result) => {
            user.ip = result.ip;
            console.log("we have the ip");
            firestore.collection("nda").add(user).then((result)=>{
                setCookie("email", usersEmail, { path: '/', domain: process.env.PUBLIC_URL});
                setCookie("first_name", usersFirstName, { path: '/', domain: process.env.PUBLIC_URL});
                setCookie("last_name", usersLastName, { path: '/', domain: process.env.PUBLIC_URL});
                console.log("query complete");
                analytics.logEvent("nda-acceptance", {
                    items: [{name: usersEmail}]
                });
            }).catch((error) => {
                sendEventToRollbar("Problem Inserting NDA information into database");
            });
        }).catch((error) => {
            console.log();
            sendEventToRollbar("Problem Resolving IP");
        });
        
    }

    return(
        <div className="terms">
            <div className="title">
                <h2>Confidential Agreeement</h2>
            </div>
            <div className="scrollable-terms-copy">
                <h3>Thank you for your interest in Chronic Ink House LA</h3>
                <p>Due to sensitivity in some of the business information shared, please agree to keep this confidentiality agreement before proceeding.</p>
                <dl>
                    <dt>1) Definition of Confidential Information</dt>
                    <dd>Definition of Confidential InformationFor purposes of this Agreement, “Confidential Information” shall include all information or materialthat has or could have commercial value or other utility in the business in which Disclosing Party isengaged including, but not limited to information or data relating to an innovation or intellectualproperty developed or owned by Disclosing Party, as well as information in a pending patent ortrademark application, confidential know-how connected with a patent or application, or innovationsor strategies that may became the basis of a patent or trademark application.</dd>
                    <dt>2) Exclusions From Confidential Information</dt>
                    <dd>Receiving Party’s obligations under this Agreement shall not extend to information that is (a) publiclyknown at the time of disclosure under this Agreement or subsequently becomes publicly knownthrough no fault of the Receiving Party; (b) discovered or created by the Receiving Party prior to thetime of disclosure by Disclosing Party; or (c) otherwise learned by the Receiving Party throughlegitimate means other than from the Disclosing Party or anyone connected with the DisclosingParty.</dd>
                    <dt>3) Obligations of Receiving Party</dt>
                    <dd>The Receiving Party shall hold and maintain the Confidential Information of the other party instrictest confidence for the sole and exclusive benefit of the Disclosing Party. The Receiving Partyshall carefully restrict access to any such Confidential Information to persons bound by thisAgreement, only on a need-to-know basis. The Receiving Party shall not, without prior writtenapproval of the Disclosing Party, use for the Receiving Party’s own benefit, publish, copy, orotherwise disclose to others, or permit the use by others for their benefit or to the detriment of theDisclosing Party, any of the Confidential Information. The Receiving Party shall return to DisclosingParty any and all records, notes, and other written, printed, or tangible materials in its possessionpertaining to the Confidential Information immediately on the written request of Disclosing Party.</dd>
                    <dt>4) Term</dt>
                    <dd>This Agreement and Receiving Party’s duty to hold Confidential Information in confidence shallremain in effect until Jan 1st, 2023 or until whichever of the following occurs first: (a) DisclosingParty sends Receiving Party written notice releasing it from this Agreement, or (b) ConfidentialInformation disclosed under this Agreement ceases to be confidential.</dd>
                    <dt>5) No Intellectual Property Rights Granted</dt>
                    <dd>This   Agreement   does   not   constitute   a   grant   or   an   intention   or   commitment   to   grant   any   right,   title   or interest   in   the   Disclosing   Party’s   innovations,   intellectual   property,   or   the   Confidential   Information,   to Receiving   Party.</dd>
                    <dt>General   Provisions</dt>
                    <dd>
                        <ol>
                            <li><strong>Relationships.</strong> Nothing contained in this Agreement shall be deemed to constitute either partya partner, joint venturer or employee of the other party for any purpose.</li>
                            <li><strong>Severability.</strong> If a court finds any provision of this Agreement invalid or unenforceable, theremainder of this Agreement shall be interpreted so as best to affect the intent of the parties.</li>
                            <li><strong>Injunctive Relief.</strong> Any misappropriation of Confidential Information in violation of thisAgreement may cause Disclosing Party irreparable harm, the amount of which may be difficult toascertain, and therefore Receiving Party agrees that Disclosing Party shall have the right to apply toa court of competent jurisdiction for an order enjoining any such further misappropriation and forsuch other relief as Disclosing Party deems appropriate. This right of the Disclosing Party is to be inaddition to the remedies otherwise available to the Disclosing Party.</li>
                            <li><strong>Attorney Fees and Expenses.</strong>  In a dispute arising out of or related to this Agreement, theprevailing party shall have the right to collect from the other party its reasonable attorney fees andcosts and necessary expenditures.</li>
                        </ol>
                    </dd>
                </dl>
            </div>
            <div className="acceptance">
                <div className="terms-acceptance">
                    <label className="checkbox-wrapper">
                        By clicking agree I hereby agree to the contents of this agreement and will abide by its terms.
                        <input className="checkbox" onChange={(event) => {checkboxChange(event)}} type="checkbox" value="true" name="accept-terms" />
                        <span className="checkmark">✘</span>
                    </label>
                </div>
                {
                    (termsAccepted) ?
                    <div className="terms-signature">
                        <input onChange={(event) => {emailChange(event)}} type="email" placeholder="Email Address" name="email-address"/>
                        <input onChange={(event) => {nameChange(event)}} type="text" placeholder="Legal First Name" name="legal-first-name" />
                        <input onChange={(event) => {lastNameChange(event)}} type="text" placeholder="Legal Last Name" name="legal-last-name" />
                    </div>
                    :
                        null
                }
                {
                    (usersFirstName && usersLastName && usersEmail != null) ?
                        <div className="terms-submit">
                            <button onClick={(event) => {handleAccept(event)}}>Accept &amp; Continue</button>
                        </div>
                    :
                        null
                }
            </div>
        </div>
    );

};

export default Terms;