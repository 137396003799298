const Thanks = () => {

    return(
        <div id="thank-you-survey" className="check-back">
            <h2>
                Thanks for Joining the Wait List!
            </h2>
            <p>
                Your feedback is very important to us. Someone from Chronic Ink will contact you shortly with the next steps.
            </p>
        </div>
    );

};

export default Thanks;