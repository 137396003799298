import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { analytics, firestore } from "../../../Firebase";
import sendEventToRollbar from "../../../Utils/Rollbar";
import Thanks from "../../Thanks";

const Survey = () => {

    const [location, setLocation] = useState(null);
    const [locationError, setLocationError] = useState(null);
    const [favCusine, setFavCusine] = useState(null);
    const [favCusineError, setFavCusineError] = useState(null);
    const [lunchRoutine, setLunchRoutine] = useState(null);
    const [lunchRoutineError, setLunchRoutineErrror] = useState(null);
    const [amenities, setAmenities] = useState(null);
    const [amenitiesError, setAmenitiesError] = useState(null);
    const [gymRoutine, setGymRoutine] = useState(null);
    const [gymRoutineError, setGymRoutineError] = useState(null);
    const [tattooStructure, setTattooStructure] = useState(null);
    const [tattooStructureError, setTattooStructureError] = useState(null);
    const [tattooSpace, setTattooSpace] = useState(null);
    const [tattooSpaceError, setTattooSpaceError] = useState(null);
    const [tattooWorkSpace, setTattooWorkSpace] = useState(null);
    const [tattooWorkSpaceError, setTattooWorkSpaceError] = useState(null);
    const [advertising, setAdvertising] = useState(null);
    const [advertisingError, setAdvertisingError] = useState(null);
    const [equipmentIHave, setEqupimentIHave] = useState(null);
    const [equipmentIHaveError, setEqupimentIHaveError] = useState(null);
    const [howIListen, setHowIListen] = useState(null);
    const [howIListenError, setHowIListenError] = useState(null);
    const [photos, setPhotos] = useState(null);
    const [photosError, setPhotosError] = useState(null);
    const [user, setUser] = useState(null);
    const [userError, setUserError] = useState(null);

    const [surveyAnswered, setSurveyAnswered] = useState(false);
    
    const [cookie, setCookie, removeCookie] = useCookies(["email", "first_name", "last_name"]);

    const handleSubmit = () => {
        console.log("starting submit", location, favCusine, lunchRoutine, amenities, gymRoutine, tattooStructure, tattooSpace, tattooWorkSpace, advertising, howIListen, photos, user);
        if(location != null && favCusine != null && lunchRoutine != null && amenities != null && gymRoutine != null && tattooStructure != null && tattooSpace != null && tattooWorkSpace != null && advertising != null && equipmentIHave != null && howIListen != null && photos != null && user != null){

                let answers = {
                    chosen_location: location,
                    chosen_cusine: favCusine,
                    chosen_lunch_routine: lunchRoutine,
                    chosen_amnenities: amenities,
                    chosen_gym_routine: gymRoutine,
                    chosen_structure: tattooStructure,
                    chosen_space: tattooSpace,
                    chosen_workspace: tattooWorkSpace,
                    chosen_advertising: advertising,
                    chosen_equpiment: equipmentIHave,
                    chosen_listening: howIListen,
                    chosen_photos: photos,
                    personal_data: user
                };

                let mail = {
                    to: user.email,
                    message:{
                        subject: "Thank you for your interest in Chronic House",
                        html: "Your feedback is very important to us. Someone from Chronic Ink will contact you shortly to determine if you are a good fit for the Chronic House."
                    }
                };

                firestore.collection('survey').add(answers).then((result) => {
                    //success
                    console.log("Form Data Was Submitted");
                    analytics.logEvent("survey submission", {
                        items: [{name: user.email}]
                    });
                    setSurveyAnswered(true);

                    //scroll to top of the thank you message
                    let elem = document.getElementById("thank-you-survey").scrollIntoView();

                    firestore.collection('mail').add(mail).then((result) => {
                        console.log("Successfully added to the mail database");
                    }).catch((error) => {
                        sendEventToRollbar("Unable to add to mail database")
                    });
                }).catch((error) => {
                    sendEventToRollbar("Survey Form Submission Error")
                });
        
        }
    };

    const validateLocation = () => {
        let loc = {
            anaheim: document.getElementsByName("location-anaheim")[0].checked,
            la_downtown: document.getElementsByName("location-downtown")[0].checked,
            la_hollywood: document.getElementsByName("location-hollywood")[0].checked,
            la_korea_town: document.getElementsByName("location-korea-town")[0].checked ,
            orange_county: document.getElementsByName("location-orange-county")[0].checked,
            sacremento: document.getElementsByName("location-sacremento")[0].checked,
            san_diego: document.getElementsByName("location-sandiego")[0].checked,
            san_jose: document.getElementsByName("location-sanjose")[0].checked
        };

        let isChecked = false;
        for(const pop in loc){
            pop ? isChecked = pop : isChecked = isChecked ;
        }

        if(isChecked){
            console.log("✔✔✔ Location data is good", loc);
            setLocation(loc);
            setLocationError(null);
        } else {
            setLocation(null);
            setLocationError("Please Select A Location");
        }
    };

    const validateFavCusine = (event) => {

        let cusine = {
            first: document.getElementsByName("favourite-cusine-one")[0].value,
            second:document.getElementsByName("favourite-cusine-two")[0].value,
            third: document.getElementsByName("favourite-cusine-three")[0].value
        }

        const goodCusine = (cusine) => {
            console.log("✔✔✔ Cusine data is good", cusine);
            setFavCusine(cusine);
            setFavCusineError(null);
        };

        const badCusine = () => {
            setFavCusine(null);
            setFavCusineError("Please Specify a Favourite Cusine");
        };

        if(cusine.first.length > 0){
            goodCusine(cusine);
        } else {
            badCusine(cusine);
        }

        
    };

    const validateLunchRoutine = (event) => {
        let routine = {
            value: event.target.value,
        };
        if(routine.value == null){
            setLunchRoutineErrror("Please Select a Lunch Routine");
            setLunchRoutine(null);
        } else {
            console.log("✔✔✔ Lunch Routine data is good", routine);
            setLunchRoutineErrror(null);
            setLunchRoutine(routine);
        }
    };

    const validateAmenities = () => {
        var amen = {
            "amenities-spaces-books": document.getElementsByName("amenities-spaces-books")[0].checked,
            "amenities-spaces-hangout": document.getElementsByName("amenities-spaces-hangout")[0].checked,
            "amenities-spaces-painting": document.getElementsByName("amenities-spaces-painting")[0].checked,
            "amenities-spaces-photoroom": document.getElementsByName("amenities-spaces-photoroom")[0].checked,
            "amenities-spaces-quietroom": document.getElementsByName("amenities-spaces-quietroom")[0].checked,
            "amenities-spaces-backyard": document.getElementsByName("amenities-spaces-backyard")[0].checked,
            "amenities-equipment-drawing-table": document.getElementsByName("amenities-equipment-drawing-table")[0].checked,
            "amenities-equipment-light-table": document.getElementsByName("amenities-equipment-light-table")[0].checked,
            "amenities-equipment-digital-tools": document.getElementsByName("amenities-equipment-digital-tools")[0].checked,
            "amenities-exercise-gym-small": document.getElementsByName("amenities-exercise-gym-small")[0].checked,
            "amenities-exercise-gym-full": document.getElementsByName("amenities-exercise-gym-full")[0].checked,
            "amenities-exercise-gym-general": document.getElementsByName("amenities-exercise-gym-general")[0].checked,
            "amenities-exercise-running-trails": document.getElementsByName("amenities-exercise-running-trails")[0].checked,
            "amenities-exercise-bike-paths": document.getElementsByName("amenities-exercise-bike-paths")[0].checked,
            "amenities-utilities-shower": document.getElementsByName("amenities-utilities-shower")[0].checked,
            "amenities-entertainment-television": document.getElementsByName("amenities-entertainment-television")[0].checked,
            "amenities-entertainment-videogames": document.getElementsByName("amenities-entertainment-videogames")[0].checked,
            "amenities-entertainment-music": document.getElementsByName("amenities-entertainment-music")[0].checked
        };

        let isChecked = false;
        for(const pop in amen){
            pop ? isChecked = pop : isChecked = isChecked ;
        }

        if(isChecked){
            console.log("✔✔✔ Amentity data is good", amen);
            setAmenities(amen);
            setAmenitiesError(null);
        } else {
            setAmenities(null);
            setAmenitiesError("Please Select Your Desired Amenities");
        }
    };

    const validateGymRoutine = (event) => {
        let gymRoutine = {
            value: event.target.value,
        };
        if(gymRoutine.value == null){
            setGymRoutineError("Please Select a Workout Routine");
            setGymRoutine(null);
        } else {
            console.log("✔✔✔ Gym Routine data is good", gymRoutine);
            setGymRoutineError(null);
            setGymRoutine(gymRoutine);
        }
    };

    const validateTattooStructure = (event) => {
        let struct = {
            value: document.querySelector('input[name="tattoo-work-structure"]:checked').value,
            note: null
        };

        const goodStruct = (value) => {
            console.log("✔✔✔ Tattoo Structure data is good", struct);
            setTattooStructureError(null);
            setTattooStructure(struct);
        };

        const badStruct = (value) => {
            setTattooStructureError("Please Select or Specify a Tattoo Work Structure");
            setTattooStructure(null);
        };

        if(struct.value == "Rental Model"){
            let otherInput = document.getElementsByName("tattoo-work-structure-rental-price")[0];
            if(otherInput.value.length > 0){
                //we are good
                struct.note = otherInput.value;
                goodStruct(struct);
            } else {
                //there is an error
                badStruct();
            }
        } else if(struct.value == null){
             //there is an error
             badStruct();
        } else if(struct.value == "Commission Split"){
            let otherInput = document.getElementsByName("tattoo-work-structure-percentage-split")[0];
            if(otherInput.value.length > 0){
                //we are good
                struct.note = otherInput.value;
                goodStruct(struct);
            } else {
                //there is an error
                badStruct();
            }
        }else {
            goodStruct(struct);
        }

    };

    const validateTattooSpace = (event) => {
        let space = {
            value: event.target.value,
        };
        if(space.value == null){
            setTattooSpaceError("Please Select a Tattoo Space Size");
            setTattooSpace(null);
        } else {
            console.log("✔✔✔ Tattoo space data is good", space);
            setTattooSpaceError(null);
            setTattooSpace(space);
        }
    };

    const validateTattooWorkSpace = (event) => {
        let workspace = {
            value: event.target.value,
        };
        if(workspace.value == null){
            setTattooWorkSpaceError("Please Select a Tattoo Space Size");
            setTattooWorkSpace(null);
        } else {
            console.log("✔✔✔ Tattoo work space data is good", workspace);
            setTattooWorkSpaceError(null);
            setTattooWorkSpace(workspace);
        }
    };

    const validateAdvertising = (event) => {
        let advert = {
            value: event.target.value,
        };
        if(advert.value == null){
            setAdvertisingError("Please Select a Tattoo Space Size");
            setAdvertising(null);
        } else {
            console.log("✔✔✔ Advertising data is good", advert);
            setAdvertisingError(null);
            setAdvertising(advert);
        }
    };

    const validateEqupimentIHave = () => {
        let eih = {
            "equipment-i-have-cabinet": document.getElementsByName("equipment-i-have-cabinet")[0].checked,
            "equipment-i-have-tattoo-tray": document.getElementsByName("equipment-i-have-tattoo-tray")[0].checked,
            "equipment-i-have-speaker": document.getElementsByName("equipment-i-have-speaker")[0].checked,
            "equipment-i-have-ipadstand": document.getElementsByName("equipment-i-have-ipadstand")[0].checked,
            "equipment-i-have-tv": document.getElementsByName("equipment-i-have-tv")[0].checked,
            "equipment-i-have-fan": document.getElementsByName("equipment-i-have-fan")[0].checked,
            "equipment-i-have-humidifier": document.getElementsByName("equipment-i-have-humidifier")[0].checked,
            "equipment-i-have-air-purification": document.getElementsByName("equipment-i-have-air-purification")[0].checked,
            "equipment-i-have-plants": document.getElementsByName("equipment-i-have-plants")[0].checked,
            "equipment-i-have-window": document.getElementsByName("equipment-i-have-window")[0].checked,
            "equipment-i-have-framed-art": document.getElementsByName("equipment-i-have-framed-art")[0].checked,
            "equipment-i-have-other": document.getElementsByName("equipment-i-have-other")[0].checked,
            "other-note":  document.getElementsByName("equipment-i-have-other-details")[0].value
        };

        let isChecked = false;
        for(const pop in eih){
            pop ? isChecked = pop : isChecked = isChecked ;
        }

        if(isChecked){
            if(eih["equipment-i-have-other"] && document.getElementsByName("equipment-i-have-other-details")[0].value.length <= 0){
                setEqupimentIHave(null);
                setEqupimentIHaveError("Please Tell Us About Your Other Equpiment");
            } else {
                console.log("✔✔✔ Equipment I have data is good", eih);
                setEqupimentIHave(eih);
                setEqupimentIHaveError(null);
            }
        } else {
            setEqupimentIHave(null);
            setEqupimentIHaveError("Please Tell Us About Your Equpiment");
        }
    };

    const validateHowIListen = (event) => {
        let listen = {
            value: event.target.value,
        };
        if(listen.value == null){
            setHowIListenError("Please let us know how you listen");
            setHowIListen(null);
        } else {
            console.log("✔✔✔ Listening data is good", listen);
            setHowIListenError(null);
            setHowIListen(listen);
        }
    };

    const validatePhotos = (event) => {
        let photo = {
            value: event.target.value,
        };
        if(photo.value == null){
            setPhotosError("Please let us know how you take photos");
            setPhotos(null);
        } else {
            console.log("✔✔✔ photo data is good", photo);
            setPhotosError(null);
            setPhotos(photo);
        }
    };

    const validateUser = () => {

        console.log("validating");
        let data = {
            fname: document.getElementsByName("firstname")[0].value,
            lname: document.getElementsByName("lastname")[0].value,
            email: document.getElementsByName("emailaddress")[0].value,
            ig: document.getElementsByName("iglink")[0].value,
        };


        console.log("user Data:", data);

        let error = "";
        let nameCheck = new RegExp('^[a-zA-Z@\\_\\-\\.]+$');
        let mailCheck = /\S+@\S+\.\S+/;
        let igCheck = new RegExp('instagram.com\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\\.(?!\\.))){0,28}(?:[A-Za-z0-9_]))?)');
    
        let first = nameCheck.test(document.getElementsByName("firstname")[0].value);
        let last = nameCheck.test(document.getElementsByName("lastname")[0].value);
        let ig = nameCheck.test(document.getElementsByName("iglink")[0].value);
        let email = mailCheck.test(document.getElementsByName("emailaddress")[0].value);
        
        console.log("Validation :", first, last, ig, email);

        if(!first){
            error += " Please add a valid First Name.";
        }
        if(!last){
            error += " Please add a Valid Last Name.";
        }
        if(!ig){
            error += " Please add a Valid IG Account Name.";
        }
        if(!email){
            error += " Please add a Valid Email Address.";
        }

        if(error != ""){
            setUser(null);
            setUserError(error);
        } else {
            setUser(data);
            setUserError(null);
        }
    
    };

    useEffect(() => {
        if(!surveyAnswered){
            let userSurveyEmail = document.getElementById("survey-email");
            let userSurveyFirstName =  document.getElementById("survey-first-name");
            let userSurveyLname =  document.getElementById("survey-last-name");
            if(userSurveyEmail.value.length === 0){
                userSurveyEmail.value = cookie.email;
            }
            if(userSurveyFirstName.value.length === 0 && cookie.first_name !== undefined){
                userSurveyFirstName.value = cookie.first_name;
            }
            if(userSurveyLname.value.length === 0 && cookie.last_name !== undefined){
                userSurveyLname.value = cookie.last_name;
            }
        }
    });

    return(
        <>
        {
            surveyAnswered ?
                <Thanks />
            :

            <div id="survey" className="survey">
            <h2>Take the Survey &amp; Join The Waitlist</h2>
            <p>Interested in joining the Chronic Ink House Family? Please answer a few questions to help us cater to your experience.</p>
            <div className="survey-wrapper">
                <section className="survey-section location">
                    <h3>Location</h3>
                    <div className="question">
                        <p>What City in California Would You Prefer to Work In?</p>
                        <ul>
                            <li><input onChange={validateLocation} type="checkbox" name="location-anaheim" value="Anaheim" />
                        <label htmlFor="location-anaheim">Anaheim</label></li>
                            <li><input onChange={validateLocation} type="checkbox" name="location-downtown" value="Los Angeles (Downtown)" />
                        <label htmlFor="location-downtown">Los Angeles (Downtown)</label></li>
                            <li><input onChange={validateLocation} type="checkbox" name="location-hollywood" value="Los Angeles (Hollywood)" />
                        <label htmlFor="location-hollywood">Los Angeles (Hollywood)</label></li>
                            <li><input onChange={validateLocation} type="checkbox" name="location-korea-town" value="Los Angeles (Korea Town)" />
                        <label htmlFor="location-korea-town">Los Angeles (Korea Town)</label></li>
                            <li><input onChange={validateLocation} type="checkbox" name="location-orange-county" value="Orange County" />
                        <label htmlFor="location-orange-county">Orange County</label></li>
                            <li><input onChange={validateLocation} type="checkbox" name="location-sacremento" value="Sacramento" />
                        <label htmlFor="location-sacremento">Sacramento</label></li>
                            <li><input onChange={validateLocation} type="checkbox" name="location-sandiego" value="San Diego" />
                        <label htmlFor="location-sandiego">San Diego</label></li>
                            <li><input onChange={validateLocation} type="checkbox" name="location-sanjose" value="San Jose" />
                        <label htmlFor="location-sanjose">San Jose</label></li>
                        </ul>
                        <div className="question-error">
                            {locationError}
                        </div>
                    </div>
                </section>
                <section className="survey-section food">
                    <h3>Food &amp; Amenities</h3>
                    <div className="question">
                        <p>1) What’s your favourite kind of food or cuisine?</p>
                        <ul className="reverse">
                            <li><input onChange={event => {validateFavCusine(event)}} type="text" name="favourite-cusine-one" placeholder="Favourite Cuisine" ></input></li>
                            <li><input onChange={event => {validateFavCusine(event)}} type="text" name="favourite-cusine-two" placeholder="Second Favourite Cuisine" ></input></li>
                            <li><input onChange={event => {validateFavCusine(event)}} type="text" name="favourite-cusine-three" placeholder="Third Favourite Cuisine" ></input></li>
                        </ul>
                        <div className="question-error">
                            {favCusineError}
                        </div>
                    </div>
                    <div className="question">
                        <p>2) When you’re at work, what’s your lunch routine?</p>
                        <ul className="reverse">
                            <li><input onChange={event => {validateLunchRoutine(event)}} type="radio" name="lunch-routine" value="Packed Lunch - Homemade" />Packed Lunch<div className="check" /></li>
                            <li className="sub-copy"><span>Homemade</span></li>
                            <li><input onChange={event => {validateLunchRoutine(event)}} type="radio" name="lunch-routine" value="Take Out" />Takeout<div className="check" /></li>
                            <li><input onChange={event => {validateLunchRoutine(event)}} type="radio" name="lunch-routine" value="Don't eat lunch - I forget" />I Forget To Eat Lunch<div className="check" /></li>
                            <li><input onChange={event => {validateLunchRoutine(event)}} type="radio" name="lunch-routine" value="Make Lunch - Kitchen Required" />Make Lunch<div className="check" /></li>
                            <li className="sub-copy"><span>Kitchen Required</span></li>
                        </ul>
                        <div className="question-error">
                            {lunchRoutineError}
                        </div>
                    </div>
                    <div className="question">
                        <p>3) What amenities are the most important to you?</p>
                        <h4>Spaces</h4>
                        <ul>
                            <li><input onChange={validateAmenities} type="checkbox" name="amenities-spaces-books" value="Books/Library" />
                        <label htmlFor="amenities-spaces-books">Books/Library</label></li>
                            <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-spaces-hangout" value="Hangout Area" />
                        <label htmlFor="amenities-spaces-hangout">Hangout Area</label></li>
                            <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-spaces-painting" value="Painting/Art Studio" />
                        <label htmlFor="amenities-spaces-painting">Painting/Art Studio</label></li>
                            <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-spaces-photoroom" value="Photography Room" />
                        <label htmlFor="amenities-spaces-photoroom">Photography Room</label></li>
                            <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-spaces-quietroom" value="Queit Room" />
                        <label htmlFor="amenities-spaces-quietroom">Quiet Room</label></li>
                            <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-spaces-backyard" value="Backyard" />
                        <label htmlFor="amenities-spaces-backyard">Backyard</label></li>
                        </ul>
                        <h4>Equipment</h4>
                        <ul>
                            <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-equipment-drawing-table" value="Books/Library" />
                        <label htmlFor="amenities-equipment-drawing-table">Drawing Table</label></li>
                            <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-equipment-light-table" value="Hangout Area" />
                        <label htmlFor="amenities-equipment-light-table">Light Table</label></li>
                            <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-equipment-digital-tools" value="Painting/Art Studio" />
                        <label htmlFor="amenities-equipment-digital-tools">Digital Tools</label></li>
                            <li className="sub-copy checkbox"><span>Wacom Screen</span></li>
                        </ul>
                        <h4>Gym</h4>
                        <ul>
                            <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-exercise-gym-small" value="Small Gym" />
                        <label htmlFor="amenities-exercise-gym-small">Small Gym</label></li>
                        <li className="sub-copy"><span>Free Weights, Treadmill, Ex. Condo Equipment</span></li>
                        <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-exercise-gym-full" value="Full Gym" />
                        <label htmlFor="amenities-exercise-gym-full">Full Gym</label></li>
                        <li className="sub-copy"><span>Squat Rack and Other Equpiment Required</span></li>
                        <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-exercise-gym-general" value="General Workout Space" />
                        <label htmlFor="amenities-exercise-gym-general">General Workout Area / Space</label></li>
                            <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-exercise-running-trails" value="Refrigerator" />
                        <label htmlFor="amenities-exercise-running-trails">Running Trails Close By</label></li>
                            <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-exercise-bike-paths" value="Refrigerator" />
                        <label htmlFor="amenities-exercise-bike-paths">Accessible Bike Paths</label></li>
                        </ul>
                        <h4>Utilities</h4>
                        <ul>
                            <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-utilities-shower" value="Shower" />
                        <label htmlFor="amenities-utilities-shower">Shower</label></li>
                        </ul>
                        <h4>Entertainment</h4>
                        <ul>
                            <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-entertainment-television" value="Stove/Stove Top" />
                        <label htmlFor="amenities-entertainment-television">Television</label></li>
                            <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-entertainment-videogames" value="Refrigerator" />
                        <label htmlFor="amenities-entertainment-videogames">Video Games / Consoles</label></li>
                            <li><input  onChange={validateAmenities}  type="checkbox" name="amenities-entertainment-music" value="Refrigerator" />
                        <label htmlFor="amenities-entertainment-music">Music / Speakers</label></li>
                        </ul>
                        <div className="question-error">
                            {amenitiesError}
                        </div>
                    </div>
                    <div className="question">
                        <p>4) How often do you exercise or go to the gym?</p>
                        <ul className="reverse">
                            <li><input onChange={(event) => {validateGymRoutine(event)}} type="radio" name="exercise-routine" value="Regular" />Regular<div className="check" /></li>
                            <li className="sub-copy"><span>3-4 Times a week</span></li>
                            <li><input onChange={(event) => {validateGymRoutine(event)}} type="radio" name="exercise-routine" value="Casual" />Casual<div className="check" /></li>
                            <li className="sub-copy"><span>1-2 Times a week</span></li>
                            <li><input onChange={(event) => {validateGymRoutine(event)}} type="radio" name="exercise-routine" value="Irregular" />I Have a Gym Membership?<div className="check" /></li>
                            <li className="sub-copy"><span>Once or Twice a Month</span></li>
                            <li><input onChange={(event) => {validateGymRoutine(event)}} type="radio" name="exercise-routine" value="Never" />What's a Gym?<div className="check" /></li>
                            <li className="sub-copy"><span>Never</span></li>
                        </ul>
                        <div className="question-error">
                            {gymRoutineError}
                        </div>
                    </div>
                </section>
                <section className="survey-section tattoo-model">
                <h3>Tattoo Model</h3>
                    <div className="question">
                        <p>1) What structure do you currently work under?</p>
                        <ul className="reverse">
                            <li><input onChange={(event) => {validateTattooStructure(event)}} type="radio" name="tattoo-work-structure" value="Commission Split" />Commission Split<div className="check" /></li>
                            <li className="sub-copy"><span>Percentage split between shop and artist on all sales</span></li>
                            <li><input onChange={(event) => {validateTattooStructure(event)}} type="text" name="tattoo-work-structure-percentage-split" placeholder="Current Percentage Paid" ></input></li>
                            <li><input onChange={(event) => {validateTattooStructure(event)}} type="radio" name="tattoo-work-structure" value="Rental Model" />Rental Model<div className="check" /></li>
                            <li className="sub-copy"><span>Monthly set rate for chair/space use.</span></li>
                            <li><input onChange={(event) => {validateTattooStructure(event)}} type="text" name="tattoo-work-structure-rental-price" placeholder="Current Rental Price" ></input></li>
                        </ul>
                        <div className="question-error">
                            {tattooStructureError}
                        </div>
                    </div>
                    <div className="question">
                        <p>2) How much space would you feel comfortable tattooing in?</p>
                        <ul className="reverse">
                            <li><input onChange={(event) => {validateTattooSpace(event)}} type="radio" name="tattoo-work-space" value="7ft" />7ft x 7ft<div className="check" /></li>
                            <li><input onChange={(event) => {validateTattooSpace(event)}} type="radio" name="tattoo-work-space" value="9ft" />9ft x  9ft<div className="check" /></li>
                            <li><input onChange={(event) => {validateTattooSpace(event)}} type="radio" name="tattoo-work-space" value="10ft plus" />Greater than 10ft x 10ft<div className="check" /></li>
                        </ul>
                        <div className="question-error">
                            {tattooSpaceError}
                        </div>
                    </div>
                    <div className="question">
                        <p>3) Please pick the best option for your preferred workspace?</p>
                        <ul className="reverse">
                            <li><input onChange={(event) => {validateTattooWorkSpace(event)}} type="radio" name="tattoo-work-space-pref" value="private" />Private<div className="check" /></li>
                            <li className="sub-copy"><span>1 Chair</span></li>
                            <li><input onChange={(event) => {validateTattooWorkSpace(event)}} type="radio" name="tattoo-work-space-pref" value="closed 2 charis" />Closed Room<div className="check" /></li>
                            <li className="sub-copy"><span>1 Chair</span></li>
                            <li><input onChange={(event) => {validateTattooWorkSpace(event)}} type="radio" name="tattoo-work-space-pref" value="closed 3 chairs" />Closed Room<div className="check" /></li>
                            <li className="sub-copy"><span>2 Chairs</span></li>
                            <li><input onChange={(event) => {validateTattooWorkSpace(event)}} type="radio" name="tattoo-work-space-pref" value="open 3+ chairs" />Open Space<div className="check" /></li>
                            <li className="sub-copy"><span>3 Chairs</span></li>
                            <li><input onChange={(event) => {validateTattooWorkSpace(event)}} type="radio" name="tattoo-work-space-pref" value="no preference" />No Preference<div className="check" /></li>
                        </ul>
                        <div className="question-error">
                            {tattooWorkSpaceError}
                        </div>
                    </div>
                    <div className="question">
                        <p>4) Would you like to have your flash designs and calendar openings to be promoted on Chronic Ink's social media and email lists?</p>
                        <ul className="reverse">
                            <li><input onChange={(event) => {validateAdvertising(event)}} type="radio" name="tattoo-chronic-promo" value="yes" />Yes<div className="check" /></li>
                            <li><input onChange={(event) => {validateAdvertising(event)}} type="radio" name="tattoo-chronic-promo" value="no" />No<div className="check" /></li>
                        </ul>
                        <div className="question-error">
                            {advertisingError}
                        </div>
                    </div>
                </section>
                <section className="survey-section equipment">
                    <h3>Equipment</h3>
                    <div className="question">
                        <p>1) Please select all of the items that can be found at your tattoo station:</p>
                        <ul>
                        <li><input onChange={validateEqupimentIHave} type="checkbox" name="equipment-i-have-cabinet" value="Cabinet Space" />
                        <label htmlFor="equipment-i-have-cabinet">Cabinet Space</label></li>
                        <li><input onChange={validateEqupimentIHave} type="checkbox" name="equipment-i-have-tattoo-tray" value="Tattooing Tray" />
                        <label htmlFor="equipment-i-have-tattoo-tray">Tattooing Tray</label></li>
                            <li><input onChange={validateEqupimentIHave} type="checkbox" name="equipment-i-have-speaker" value="Speaker" />
                        <label htmlFor="equipment-i-have-speaker">Speaker</label></li>
                            <li><input onChange={validateEqupimentIHave} type="checkbox" name="equipment-i-have-ipadstand" value="iPad Stand" />
                        <label htmlFor="equipment-i-have-ipadstand">iPad Stand</label></li>
                            <li><input onChange={validateEqupimentIHave} type="checkbox" name="equipment-i-have-tv" value="TV" />
                        <label htmlFor="equipment-i-have-tv">TV</label></li>
                            <li><input onChange={validateEqupimentIHave} type="checkbox" name="equipment-i-have-fan" value="Fan" />
                        <label htmlFor="equipment-i-have-fan">Fan</label></li>
                            <li><input onChange={validateEqupimentIHave} type="checkbox" name="equipment-i-have-humidifier" value="Humidifier" />
                        <label htmlFor="equipment-i-have-humidifier">Humidifier</label></li>
                            <li><input onChange={validateEqupimentIHave} type="checkbox" name="equipment-i-have-air-purification" value="Air Purifier" />
                        <label htmlFor="equipment-i-have-air-purification">Air Purifier</label></li>
                            <li><input onChange={validateEqupimentIHave} type="checkbox" name="equipment-i-have-plants" value="Plants" />
                        <label htmlFor="equipment-i-have-plants">Plants</label></li>
                            <li><input onChange={validateEqupimentIHave} type="checkbox" name="equipment-i-have-window" value="Window" />
                        <label htmlFor="equipment-i-have-window">Window</label></li>
                            <li><input onChange={validateEqupimentIHave} type="checkbox" name="equipment-i-have-framed-art" value="Framed Art" />
                        <label htmlFor="equipment-i-have-framed-art">Framed Art</label></li>
                            <li><input onChange={validateEqupimentIHave} type="checkbox" name="equipment-i-have-other" value="Other" />
                        <label htmlFor="equipment-i-have-other">Other</label></li>
                        <li><input onChange={validateEqupimentIHave} type="text" name="equipment-i-have-other-details" placeholder="Lamp, Ring Light, etc."></input></li>
                        </ul>
                        <div className="question-error">
                            {equipmentIHaveError}
                        </div>
                    </div>
                    <div className="question">
                        <p>2) When tattooing, how do you listen to music or podcasts?</p>
                        <ul>
                            <li><input onChange={(event) => {validateHowIListen(event)}} type="checkbox" name="equipment-how-i-listen" value="Bluetooth Speaker - Medium Vol" />Bluetooth Speaker</li>
                            <li className="sub-copy"><span>Medium Volume</span></li>
                            <li><input onChange={(event) => {validateHowIListen(event)}} type="checkbox" name="equipment-how-i-listen" value="Bluetooth Speaker - Loud Vol" />Bluetooth Speaker</li>
                            <li className="sub-copy"><span>Loud Volume</span></li>
                            <li><input onChange={(event) => {validateHowIListen(event)}} type="checkbox" name="equipment-how-i-listen" value="Headphones or AirPods" />Headphones or AirPods</li>
                            <li><input onChange={(event) => {validateHowIListen(event)}} type="checkbox" name="equipment-how-i-listen" value="iPad Speaker/Device Speaker" />iPad Speaker/Device Speaker</li>
                            <li><input onChange={(event) => {validateHowIListen(event)}} type="checkbox" name="equipment-how-i-listen" value="None" />None</li>
                        </ul>
                        <div className="question-error">
                            {howIListenError}
                        </div>
                    </div>
                    <div className="question">
                        <p>3) What do you use to take photos of your tattoos?</p>
                        <ul className="reverse">
                            <li><input onChange={(event) => {validatePhotos(event)}} type="radio" name="take-photos" value="Smartphone" />Smartphone<div className="check"/></li>
                            <li><input onChange={(event) => {validatePhotos(event)}} type="radio" name="take-photos" value="DSLR + Professional Equpiment" />DSLR + Professional Equipment<div className="check"/></li>
                            <li><input onChange={(event) => {validatePhotos(event)}} type="radio" name="take-photos" value="Point and Shoot / Other" />Point and Shoot / Other<div className="check"/></li>
                        </ul>
                        <div className="question-error">
                            {photosError}
                        </div>
                    </div>
                </section>
                <section className="survey-section waitlist">
                    <h3>Review</h3>
                    <div className="question">
                        <p className="blurb">
                            <strong>Admittance into the Chronic House is going to be on a limited basis.</strong>
                            <br/><br/>
                            If you're interested, please join the waitlist so we can reach out to you for a chat.
                        </p>
                        <p className="title">Enter Your Personal Information:</p>
                        <p>
                            <label htmlFor="name">First Name</label>
                            <input id="survey-first-name" onChange={validateUser} type="text" name="firstname"></input>
                        </p>
                        <p>
                            <label htmlFor="name">Last Name</label>
                            <input id="survey-last-name" onChange={validateUser} type="text" name="lastname"></input>
                        </p>
                        <p>
                            <label htmlFor="portfolio">Instagram User Name</label>
                            <input onChange={validateUser} type="text" name="iglink"></input>
                        </p>
                        <p>
                            <label htmlFor="name">Email</label>
                            <input id="survey-email" onChange={validateUser} type="email" name="emailaddress"></input>
                        </p>
                        <div className="question-error">
                            {userError}
                        </div>
                    </div>
                    <section className="survey-section submit">
                    <p>
                        <button onClick={handleSubmit} className="wait-list-submit">Submit and Join Wait List</button>
                    </p>
                </section>
                </section>
            </div>
        </div>
        }
        </>
    );

};

export default Survey;