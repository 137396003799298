import MenuButtonPress from '../../../Utils/MenuButtonPress';

const Header = () => {

    return(
        <div className="header">
            <button disabled onClick={MenuButtonPress} className="menu-button">
            
            </button>
            <span className="main-logo"></span>
            <a href="#survey" className="cta-button">JOIN NOW</a>
        </div>
    );

};

export default Header;