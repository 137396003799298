const WhatYouGet = () => {

    return (
        <div id="perks" className="wdyg">
          <div className="lead">
            <h2>Perks</h2>
            <h3>What You Get</h3>
            <p>With access to quality support, Chronic House gives you all the tools you need to run your own business.</p>
          </div>
          <div className="perks-include">
            <h3><span>Perks Include</span></h3>
            <div className="list-area">
              <ol>
                <li>
                  <div><span className="marker"><span>1</span></span>Concierge Service To Assist with Clients</div>
                </li>
                <li>
                <div className="has-sub"><span className="marker"><span>2</span></span>Access to Chronic Ink’s marketing and business assets</div>
                  <ul>
                    <li>181K Instagram Followers</li>
                    <li>120K Email Subscribers</li>
                  </ul>
                </li>
                <li>
                <div className="has-sub"><span className="marker"><span>3</span></span> Collab Network</div>
                  <ul>
                    <li>
                      Herschel Supply Co. &amp; others!
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
            <div className="logo-area">
                <img src={process.env.PUBLIC_URL + "/images/Lulu@2x.png"} />
                <img src={process.env.PUBLIC_URL + "/images/Aston Martin@2x.png"} />
                <img src={process.env.PUBLIC_URL + "/images/BMW@2x.png"} />
                <img src={process.env.PUBLIC_URL + "/images/Sony Music@2x.png"} />
                <img src={process.env.PUBLIC_URL + "/images/Epic Records@2x.png"} />
                <img src={process.env.PUBLIC_URL + "/images/Hennessy@2x.png"} />
                <img src={process.env.PUBLIC_URL + "/images/Herschel@2x.png"} />
            </div>
            <div className="list-area">
              <ol start="4">
                <li>
                <div className="has-sub"><span className="marker"><span>4</span></span>Travel!</div>
                  <ul>
                    <li>The ability to Guest Spot in other Chronic Ink locations.</li>
                  </ul>
                </li>
                <li>
                <div><span className="marker"><span>5</span></span>Custom Catered Tattoo Supply Programs</div>
                </li>
                <li>
                <div className="has-sub"><span className="marker"><span>6</span></span>Ask The Experts</div>
                  <ul>
                    <li>
                      Have a specific question on operating or optimizing your tattoo business?
                    </li>
                    <li>
                      Set-up one-on-one calls with Chronic Ink’s marketing and/or operations team.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>


            <h3 className="resources-title"><span>Exclusivity</span></h3>
            <div className="list-area final">
              <ol start="4">
                <li>
                <div className="has-sub"><span className="marker"><span>A</span></span>Exclusivity</div>
                  <ul>
                    <li>Future entry into the Chronic Ink House will require recommendations from two current staff members. </li>
                  </ul>
                </li>
                <li>
                <div className="has-sub"><span className="marker"><span>B</span></span>Security</div>
                <ul>
                    <li>
                        Security system located throughout the property.
                    </li>
                    <li>
                        Everyone working in a Chronic Ink House will go through a comprehensive pre-screening process.
                    </li>
                    <li>
                        Signed Codes of Conduct
                    </li>
                  </ul>
                </li>
                <li>
                <div className="has-sub"><span className="marker"><span>C</span></span>Healty and Safety</div>
                  <ul>
                    <li>
                        Insurance Coverage
                    </li>
                    <li>
                        Access to Chronic Ink’s Human Resources Department
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>
      );

};

export default WhatYouGet; 