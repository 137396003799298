import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {auth} from '../../Firebase/';

const Login = () => {

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [cookie, setCookie, removeCookie] = useCookies(['email']);

    const changeHandler = (event) => {
        const {name, value} = event.currentTarget;
        console.log("Change", event, name, value);
        if(name == 'email'){
            setEmail(value);
        } else if(name == 'password'){
            setPassword(value);
        }
    };

    const signIn = (email, password) => {
        console.log("About to sign in for:", email);
        let newAuth = auth.signInWithEmailAndPassword(email, password);
        console.log(newAuth);
    }

    const togglePasswordVisibility = () => {
        let field = document.getElementById("user-password");
        let button = document.getElementById("pword-toggle");
        let ident = "text";
        if(field.type == ident){
            //we have a text field
            field.type = "password";
            button.classList.add("on");
        } else {
            //we have a password field
            field.type = ident;
            button.classList.remove("on");
        }
    }

    useEffect(() => {
        if(cookie.email){
            let targetInput = document.getElementById("login-email");
            if(email == null){
                console.log("setting email");
                let userEmail = String(cookie.email).trim();
                targetInput.value = userEmail;
                setEmail(userEmail);
            }
        }
    });

    return(
        <div className="login">
            <img className="welcome" src={process.env.PUBLIC_URL + "/images/Welcome To.png"} />
            <h1>Chronic <br/> House</h1>
            <p>
                <label htmlFor="email">Email </label>
                <input id="login-email" type="email" name="email" placeholder="Enter Email" onChange={(event) => {changeHandler(event)}} />
            </p>
            <p>
                <label htmlFor="password">Password </label>
                <input id="user-password" type="password" name="password" placeholder="Enter Password"  onChange={(event) => {changeHandler(event)}} />
                <span id="pword-toggle" onClick={togglePasswordVisibility}></span>
            </p>
            <p className="submit">
                <button className="cta-button" onClick={(event) => {signIn(email, password)}}>Start Your Next Adventure</button>
            </p>
        </div>
    );

}

export default Login;