const Amenities = () => {

    return (
        <div id="amenities" className="amenities">
          <h2>Amenities</h2>
          <h3>More Than Just a House</h3>
            <img className="art-room" src={process.env.PUBLIC_URL + "/images/Amenities-Art-Room.jpg"} />
          <div className="image-left">
            <img src={process.env.PUBLIC_URL + "/images/Amenities-Yoga-Room.jpg"} />
          </div>
          <div className="image-right">
            <img src={process.env.PUBLIC_URL + "/images/Outdoor Seating Area Grain.jpg"} />
          </div>
          <img className="couch" src={process.env.PUBLIC_URL + "/images/Meditation Couch.png"} />
          <img className="photo" src={process.env.PUBLIC_URL + "/images/Photo Studio FX@2x.png"} />
          <div className="image-split">
             <img className="photo outdoor" src={process.env.PUBLIC_URL + "/images/Outdoor Couch.jpg"} />
             <div className="image-stack">
              <img className="photo" src={process.env.PUBLIC_URL + "/images/Living Room.jpg"} />
              <img className="photo" src={process.env.PUBLIC_URL + "/images/Reading Area.jpg"} />
             </div>
            <img className="photo gym" src={process.env.PUBLIC_URL + "/images/Home Gym FX@3x.png"} />
          </div>
        </div>
      );

};

export default Amenities;