import Rollbar from "rollbar";

const rb = new Rollbar({
    accessToken: '78ef6e7fecda476083ea98dc9565ebd0',
    captureUncaught: true,
    captureUnhandledRejections: true,
    captureIp: true,
    captureUsername: true,
    payload: {
        environment: "production"
    }
});

const sendEventToRollbar = (toSend) => {
    rb.info(toSend);
};

export default sendEventToRollbar;