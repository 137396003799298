const Footer = () => {

    return(
        <footer>
            <div className="logo-container">
            <img className="footer-logo" src={process.env.PUBLIC_URL + "/images/Chronic-House-Koi-Circle.png"} />
            </div>
            <div className="footer-links">
            <ul>
                <li>
                <a href="#start">About Chronic</a>
                </li>
                <li>
                <a href="#beliefs">Our Beliefs</a>
                </li>
                <li>
                <a href="#vision">House Vision</a>
                </li>
                <li>
                <a href="#space">The Space</a>
                </li>
                <li>
                <a href="#amenities">Amenities</a>
                </li>
            </ul>
            <ul>
            <li>
                <a href="#amenities">Amenities</a>
                </li>
                <li>
                <a href="#perks">Perks</a>
                </li>
                <li>
                <a id="#survey">Take Survey</a>
                </li>
                <li>
                <a href="https://www.instagram.com/chronicink/" target="_blank">instagram</a>
                </li>
                <li>
                    <a href="https://www.chronicinktattoo.com/" target="_blank">Chronic Ink</a>
                </li>
            </ul>
            </div>
            <h2>
            Chronic
            <br />
            House
            </h2>
            <p>&copy; 2021 Chronic Ink Tattoo.</p>
        </footer>
    );

};

export default Footer;