import pullQuote from '../../../images/inspire.svg';

const Vision = () => {
    return (
        <div id="vision" className="vision">
          <div className="leader">
            <img src={process.env.PUBLIC_URL + "/images/Meditation Space FX.png"} />
            <img className="vision-title" src={process.env.PUBLIC_URL + "/images/The Vision.png"} />
            <img src={process.env.PUBLIC_URL + "/images/Kitchen FX.png"} />
          </div>
          <img className="art-deco" src={process.env.PUBLIC_URL + "/images/Art Deco Shapes.png"} />
          <h3>Chronic House Is Built With The Artist At Its Center</h3>
          <p className="lead-paragraph">
            It exists as a blend of autonomy and support. In 2021, an artist should feel they have complete control over their own space, schedule, clients, career but also have access to quality support as they need it, whether that's with bookings, social media, or artistic development.
          </p>
          <img className="studio-space" src={process.env.PUBLIC_URL + "/images/Vision-Studio-Space.jpg"} />
          <p>
            The Chronic House offers artists an ability to have their own studio inside the House.
          </p>
          <p>
            Common areas are designed and styled to help elevate an artist's brand, provide potential for social content, and help integrate their hobbies and routines into a private controlled studio space.
          </p>
          <div className="image-layout-1">
            <img src={process.env.PUBLIC_URL + "/images/Vision-Interior-Windows.jpg"} />
            <img src={process.env.PUBLIC_URL + "/images/Vision-Exterior-Stairs.jpg"} />
          </div>
          <img className="pull-quote-image" src={pullQuote} alt="We believe that beyond offering utility, a studio's purpose is to inspire" />
          <p>
            We prioritize getting better together from an intellectual openness. Sharing experiences, stories, and tools from a vast array of fantastic guest artists to the residence inside the house. Chronic House will help facilitate and push the growth of everyone under the roof.
          </p>
          <h4 className="hassle-free"><span>Benefits</span></h4>
          <p>
            Chronic House gives you the benefits of your own studio without the hassle of risking your own money, dealing with leases + landlords, insurance and liabilities, health inspections, ordering supplies etc.  This way you can focus on your art while maintaining complete control over your own career.
          </p>
          <div className="image-layout-1 reverse">
            <img src={process.env.PUBLIC_URL + "/images/Lightable FXHi.png"} />
            <img src={process.env.PUBLIC_URL + "/images/Vision-Stairs.jpg"} />
          </div>
          <p>
            Aside from the tattooing aspects of the House, our greater vision is to strengthen the artistic community. Bringing different worlds together, exposing one another to new cultures, perspectives and understanding what we’re all able to achieve when we’re able to build and network in meaningful ways.
          </p>
          <img className="vision-gallery" src={process.env.PUBLIC_URL + "/images/Vision-Gallery.jpg"} />
          <p>
            The House will host a range of relevant programming to realize this vision, whether that be art shows, performances, seminars, networking events or corporate collaboration activations.
          </p>
        </div>
      );
};

export default Vision;