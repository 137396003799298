const MenuButtonPress = () => {
    console.log(
      'click',
      document.getElementById('chronic-house').classList.contains('active'),
    );
    if (document.getElementById('chronic-house').classList.contains('active')) {
      document.getElementById('chronic-house').classList.remove('active');
    } else {
      document.getElementById('chronic-house').classList.add('active');
    }
  };
  
  export default MenuButtonPress;  