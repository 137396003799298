import keyholeSVG from '../../../images/keyholemask.svg';

const Space = () => {
    return (
        <div id="space" className="space">
          <div className="lead">
            <h2>The Space</h2>
            <div className="keyhole-zoom">
              <div id="cover" className="keyhole-cover" style={{backgroundImage: `url(${keyholeSVG})`}} />
              
            </div>
          </div>
          <h3>The Inspiration</h3>
          <div className="image-left">
            <img src={process.env.PUBLIC_URL + "/images/Space-Zen-Tree.jpg"} />
          </div>
          <div className="image-right">
          <img src={process.env.PUBLIC_URL + "/images/Hallway Door FX@2x.png"} />
          </div>
          <img className="full-width" src={process.env.PUBLIC_URL + "/images/Porch.jpg"} />
          <div className="image-left second">
          <img src={process.env.PUBLIC_URL + "/images/Modern Kitchen FX@2x.png"} />
          </div>
        </div>
      );
};

export default Space;