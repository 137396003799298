import Home from "../Components/Home";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Navigation from "../Components/Navigation";
import Survey from "../Components/Survey";
import Beliefs from "../Components/Beliefs";
import About from "../Components/About";
import Vision from "../Components/Vision";
import Space from "../Components/Space";
import Amenities from "../Components/Amenities";
import WhatYouGet from "../Components/WhatYouGet";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import sendEventToRollbar from "../../Utils/Rollbar";
import { firestore } from "../../Firebase";
import CheckBack from "../Components/CheckBack";
import ScrollMagic from 'scrollmagic';
import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

const ChronicHouse = () => {

    const [cookie, setCookie, removeCookie] = useCookies(["email"]);
    const [surveyAnswered, setSurveyAnswered] = useState(false);
    const [animatedIn, setAnimatedIn] = useState(false);
    const [keyholeSize, setKeyholeSize] = useState(100);
    const controller = new ScrollMagic.Controller({
        container: "body",
        loglevel: 0
    });

    ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

    useEffect(() => {
        if(cookie.email !== undefined){
            //we have an NDA cookie lets see if the person has done a survey
            firestore.collection("survey").where("personal_data.email", "==", cookie.email).get().then((result) => {
                if(!result.empty){
                  console.log("✅✅ this user has submitted a survey", result);
                  setSurveyAnswered(true);
                } else {
                  console.log("❌❌❌ user has not submitted the survey");
                  setSurveyAnswered(false);
                }
            }).catch((error) => {
                sendEventToRollbar("Issue looking for compleated survey");
            });
        }

        //setup animations
        let tween = new TweenMax.to('#cover', 2, {
            backgroundSize: '625%'
        });
        
        let scene = new ScrollMagic.Scene({
            duration: 812,
            triggerElement: ".lead",
            reverse: true,
        }).setTween(tween).addTo(controller);

    });

    

    return(
        <>
            <Header />
            <div className="content">
                <main>
                    <Home />
                    <About />
                    <Beliefs />
                    <Vision />
                    <Space />
                    <Amenities />
                    <WhatYouGet />
                    {
                        surveyAnswered ?
                            <CheckBack />
                        :
                            <Survey />
                    }
                </main>
            </div>
            <Footer />
        </>
    );

}

export default ChronicHouse;