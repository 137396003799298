import AboutSlider from "./Slider"
import pullQuote from '../../../images/artists.svg';
import pullQuoteTwo from '../../../images/to-date-pullquote.svg'

const About = () => {
    return (
        <div id="start" className="about">
          <h3>About Chronic Ink</h3>
          <h2>12 Years &amp; Evolving</h2>
          <p>
            Chronic Ink was founded in 2008 and grew from a small 300 sq. ft. space
            to multiple studios across Canada.
          </p>
          <div className="image-group-1">
            <img src={process.env.PUBLIC_URL + "/images/About-Zen-Stairs.jpg"} />
            <img src={process.env.PUBLIC_URL + "/images/About-Tattoo-Asian-Ed-Mountain.jpg"} />
          </div>
          <img className="interrior-beauty" src={process.env.PUBLIC_URL + "/images/About-Living-Room.jpg"} />
          <img className="pull-quote-image to-date" src={pullQuoteTwo} alt="Over 200 Industry awards" />
          <div className="image-group-2">
            <img src={process.env.PUBLIC_URL + "/images/About-Chronic-Lulu-Collab.jpg"} />
            <img src={process.env.PUBLIC_URL + "/images/About-Chronic-Aston-Collab.jpg"} />
          </div>
          <p>
            We've had the honor of collaborating on several projects with&nbsp;
            <a target="_blank">
              Aston Martin
            </a>
            ,&nbsp;
            <a target="_blank">
              BMW
            </a>
            ,&nbsp;
            <a target="_blank">
              Hennessy
            </a>
            , and&nbsp;
            <a target="_blank">
              Lululemon&nbsp;
            </a>
            to name a few.
          </p>
          <p className="less-bottom">
            We've also welcomed more than 300 international guest artists to our
            various studios
          </p>
          <img className="pull-quote-image" src={pullQuote} alt=" over 200 industry awards" />
          <h3>Home Grown Talent</h3>
          <p>
            We are most proud of the artists that have gone onto become household
            names through our in house apprenticeship program, some of whom you know
            as:
          </p>
          <div className="slider-container">
            <AboutSlider />
          </div>
        </div>
      );
};

export default About;