import React, {useEffect, useState} from 'react';
import ChronicHouse from './Sections/ChronicHouse';
import Login from './Sections/Login';
import Terms from './Sections/Terms';
import {analytics, auth, firestore} from './Firebase/';
import { useCookies } from 'react-cookie';
import { useHotjar } from './Utils/HotJar';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './Styles/app.scss';


function App() {

  const [ndaAcceptance, setNdaAcceptance] = useState(false);
  const [user, setUser] = useState(null);
  const [cookie, setCookie, removeCookie] = useCookies(["email"]);

  auth.onAuthStateChanged(userAuth => {
    setUser(userAuth);
    if(userAuth){
      analytics.logEvent("successful_login", {
        items: [{name: cookie.email}]
    }); 
    }
  });

  //init hotjar heatmaping etc.
  useHotjar();

  useEffect(() => {

      //check to see if the user has accepted the nda
      if(!ndaAcceptance){
        //check to see if there is an existing cookie with data
        if(cookie.email === undefined){
          console.log("🍪🍪 we don't have an email in the cookie - show the nda");
          setNdaAcceptance(false);
        } else {
          //check the firebase database to see if the user has accepted
          firestore.collection("nda").where("email", "==", cookie.email).get().then((result) => {
            if(!result.empty){
              console.log("✅✅ this user has signed the nda", result);
              setNdaAcceptance(true);
            } else {
              console.log("❌❌❌ user has not signed the NDA");
              setNdaAcceptance(false);
            }
            
          }).catch((error) =>{
            console.log("🙅‍♂️🙅‍♂️ this user has not signed the NDA", error);
            setNdaAcceptance(false);
          });
        }
      }


  });

  return (
    <>
      <div id="chronic-house">
        {
          ndaAcceptance ?
            user ?
              <ChronicHouse />
            :
              <Login />
          :
            <Terms />
        }
      </div>
      <div id="warning">
      <img src={process.env.PUBLIC_URL + "/images/Chronic-House-Koi-Circle.png"} />
      <h1>Chronic<br/>House</h1>
        <p>
          For the best experience please view this site on a mobile device.
        </p>
      </div>
    </>
  );
}

export default App;
