const Beliefs = () => {

    return (
    <div id="beliefs" className="beliefs">
      <h2>We Believe Tattooing Is a Privilege</h2>
      <p>
        Thus, we believe in sharing our knowledge — it's our way of paying this
        blessing forward.
      </p>
      <p>
        We believe this exchange from the heart for a better life for others
        will always be our greatest value and differentiator.
      </p>
      <div className="linkout-box">
        <div className="avatar-container">
            <img src={process.env.PUBLIC_URL + "/images/DavidAvatar.png"} />
        </div>
        <div className="link-container">
          <a href="https://www.chronicinktattoo.com/blog/davids-story/" target="_blank">
            From Apprentice to Owner, the David Hoang Story ↗
          </a>
        </div>
      </div>
    </div>
  );

};

export default Beliefs;