const CheckBack = () => {

    return(
        <div className="check-back">
            <h2>
                Check Back Soon!
            </h2>
            <p>
                Thank you for your feedback. Someone from Chronic Ink will contact you shortly with details about the next steps.
            </p>
        </div>
    );

};

export default CheckBack;