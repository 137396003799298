import React from 'react';
import Slider from 'react-slick';

const AboutSlider = () => {

    const sliderSettings = {
        dots: true,
        arrows: true,
        autoplay: true,
        centerMode: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };

      return (
        <Slider {...sliderSettings}>
          <div className="slider-item">
            <div className="wrapper">
                <img className="big" src={process.env.PUBLIC_URL + "/images/Asian – David FX.png"} />
              <div className="data-wrapper">
              <img className="avatar" src={process.env.PUBLIC_URL + "/images/David.png"} />
                <a href="" target="_blank">
                  @davidhoangtattoo
                </a>
              </div>
            </div>
          </div>
    
          <div className="slider-item">
            <div className="wrapper">
                <img className="big small" src={process.env.PUBLIC_URL + "/images/Asian – Tony FX.png"} />
              <div className="data-wrapper">
              <img className="avatar" src={process.env.PUBLIC_URL + "/images/Tony.png"} />
                <a href="" target="_blank">
                  @tonyhu_chronicink
                </a>
              </div>
            </div>
          </div>
    
          <div className="slider-item">
            <div className="wrapper">
            <img className="big" src={process.env.PUBLIC_URL + "/images/Small – Joanna FX.png"} />
              <div className="data-wrapper">
              <img className="avatar" src={process.env.PUBLIC_URL + "/images/Joanna.png"} />
                <a href="" target="_blank">
                  @joannamroman
                </a>
              </div>
            </div>
          </div>
    
          <div className="slider-item">
            <div className="wrapper">
            <img className="big small" src={process.env.PUBLIC_URL + "/images/Realism – BKS FX.png"} />
              <div className="data-wrapper">
              <img className="avatar" src={process.env.PUBLIC_URL + "/images/BKS.png"} />
                <a href="" target="_blank">
                  @artofsteve
                </a>
              </div>
            </div>
          </div>
    
          <div className="slider-item">
            <div className="wrapper">
            <img className="big" src={process.env.PUBLIC_URL + "/images/Asian – Patrick FX.png"} />
              <div className="data-wrapper">
              <img className="avatar" src={process.env.PUBLIC_URL + "/images/Patrick.png"} />
                <a href="" target="_blank">
                  @the_burning_jewel
                </a>
              </div>
            </div>
          </div>
    
          <div className="slider-item">
            <div className="wrapper">
            <img className="big small" src={process.env.PUBLIC_URL + "/images/Realism – George FX.png"} />
              <div className="data-wrapper">
              <img className="avatar" src={process.env.PUBLIC_URL + "/images/George.png"} />
                <a href="" target="_blank">
                  @george_chronicink
                </a>
              </div>
            </div>
          </div>
    
          <div className="slider-item">
            <div className="wrapper">
            <img className="big" src={process.env.PUBLIC_URL + "/images/Brush – Karen FX.png"} />
              <div className="data-wrapper">
              <img className="avatar" src={process.env.PUBLIC_URL + "/images/Karen.png"} />
                <a href="" target="_blank">
                  @karen_tattoo
                </a>
              </div>
            </div>
          </div>
    
          <div className="slider-item">
            <div className="wrapper">
            <img className="big small" src={process.env.PUBLIC_URL + "/images/Tattoo-Watercolor-Shirley.jpg"} />
              <div className="data-wrapper">
              <img className="avatar" src={process.env.PUBLIC_URL + "/images/Shirley.png"} />
                <a href="" target="_blank">
                  @shirleyliang.tattoos
                </a>
              </div>
            </div>
          </div>
        </Slider>
      );

};

export default AboutSlider;