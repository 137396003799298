import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/firestore';   // for cloud firestore
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyANSjqzNS_HYKs5XFyh0mOkqBmr0ZUujoc",
    authDomain: "chronic-house.firebaseapp.com",
    databaseURL: "https://chronic-house-default-rtdb.firebaseio.com",
    projectId: "chronic-house",
    storageBucket: "chronic-house.appspot.com",
    messagingSenderId: "643986446094",
    appId: "1:643986446094:web:5e4697fe7342389fb5a95f",
    measurementId: "G-F7G379Z47B"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const analytics = firebase.analytics();