const Home = () => {

    return(
        <div className="home">
            <h1>
                Chronic
                <br />
                House
            </h1>
            <img className="home-image" src={process.env.PUBLIC_URL + "/images/House-Interrior.png"} />
            <h2>Los Angeles - 2021/22</h2>
            <a href="#start" className="cta-button">Learn More</a>
        </div>
    );

};

export default Home